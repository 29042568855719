<template>
  <nav class="flex justify-between items-center gap-8 h-8 lg:h-12">
    <div class="hidden lg:block">
      <Button variant="light" class="font-bold min-w-[8rem]" :class="{'invisible': !navStore.previous}" :disabled="$loadingstore.validating_booking" @click="gotoPreviousStep()">{{ $t('previous') }}</Button>
    </div>
    <div class="flex items-center lg:hidden gap-4" @click="receipt?.toggle()">
        <div class="bg-primary rounded-full w-10 h-10 hidden 2xs:flex justify-center items-center flex-none">
          <Recipe class="text-white w-6" />
        </div>
        <div>
          <p class="text-xs leading-none">{{ $t('view_reservation') }}</p>
          <Price :value="$flowstore.checkout.meta.total_price" class="font-bold text-lg" />
        </div>
    </div>
    <div class="gap-6 lg:gap-8 flex-1 max-w-screen-md hidden lg:flex">
      <div :class="{ 'text-primary': navStore.current === 'reservation' }" class="w-1/4">
        <ProgressIndicator :progress="navStore.progress.reservation" />
        <span class="block text-sm truncate">
          <span class="font-bold mr-2">1</span>
          {{ $t('steps.reservation') }}
        </span>
      </div>

      <div v-if="navStore.progress.choices !== undefined" :class="{ 'text-primary': navStore.current === 'choices' }" class="w-1/4">
        <ProgressIndicator :progress="navStore.progress.choices" />
        <span class="block text-sm truncate">
          <span class="font-bold mr-2">2</span>
          {{ $t('steps.choices') }}
        </span>
      </div>

      <div :class="{ 'text-primary': navStore.current === 'customer' }" class="w-1/4">
        <ProgressIndicator :progress="navStore.progress.customer" />
        <span class="block text-sm truncate">
          <span class="font-bold mr-2">{{ navStore.progress.choices !== undefined ? 3 : 2 }}</span>
          {{ $t('steps.customer_details') }}
        </span>
      </div>

      <div class="w-1/4">
        <ProgressIndicator :progress="navStore.progress.complete" />
        <span class="block text-sm truncate">
          <span class="font-bold mr-2">{{ navStore.progress.choices !== undefined ? 4 : 3 }}</span>
          {{ $t('steps.complete') }}
        </span>
      </div>
    </div>
    <div>
      <Button v-if="navStore.next?.name === 'complete'" variant="secondary" class="font-bold min-w-[8rem]" :class="{'invisible': !navStore.next}" @click="createBooking" :disabled="$loadingstore.validating_booking" data-cy="step-navigation-button-pay">{{ $t('pay') }}</Button>
      <Button v-if="navStore.next?.name !== 'complete'" variant="secondary" class="font-bold min-w-[8rem]" :class="{'invisible': !navStore.next}" @click="gotoNextStep()" data-cy="step-navigation-button-next">{{ $t('next') }}</Button>
    </div>
  </nav>

  <ReceiptOverlay ref="receipt" />
</template>

<script lang="ts" setup>
  import { ref } from 'vue';
  import { useFlowStore } from '@/stores/useFlowStore';
  import { useNavigationStore } from '@/stores/useNavigationStore';
  import { isAxiosError } from 'axios';
  import Button from '@/components/Button.vue';
  import ProgressIndicator from '@/components/ProgressIndicator.vue';
  import Recipe from '@/theme/icons/recipe.svg?component';
  import Price from '@/components/Price.vue';
  import ReceiptOverlay from '@/components/ReceiptOverlay.vue';
  import { isIntergrated, sendMessage } from '@/lib/comm';

  const flowStore = useFlowStore();
  const navStore = useNavigationStore();

  const receipt = ref<InstanceType<typeof ReceiptOverlay>>();


  /**
   * Skip empty upsell steps and navigate to the next step.
   *
   * @param forwards Navigate in a forwards direction
   */
  const skipEmptyUpsellStepsAndNavigate = async (forwards: boolean = true) => {
    const availableSteps = forwards ? navStore.upcomingSteps : (navStore.passedSteps ? navStore.passedSteps.toReversed() : []);
    const skipAllStep = forwards ? 'customer' : 'reservation';

    let allStepsSkipped = false;

    for (const upsell of availableSteps) {
      if (upsell.parent !== 'choices') continue; // Skip staps that are not part of the choices (upsells)

      let upsellProducts = flowStore.checkout.meta.upsells[upsell.name].products;

      // Lunch and diner step is a combined upsell step
      if (upsell.name === 'lunch_and_diner') {
        upsellProducts = [...upsellProducts, ...flowStore.checkout.meta.upsells.hotel_rooms.products];
      }

      // Filter out products that are not visually available
      const availableProducts = upsellProducts.filter(product => product.is_available || !product.hide_when_not_available);

      if (availableProducts.length === 0) {
        allStepsSkipped = true;
        console.log(`Skipping upsell step ${upsell.name}`);
      } else {
        console.log(`Going to upsell step ${upsell.name} = ${availableProducts.length}`);
        return await navStore.gotoStep(upsell.name);
      }
    }

    if (allStepsSkipped) {
      console.log(`All upsells skipped, going to ${skipAllStep}`);
      return await navStore.gotoStep(skipAllStep);
    }

    if (forwards) {
      return await navStore.gotoNextStep();
    } else {
      return await navStore.gotoPreviousStep();
    }
  };

  /**
   * Navigate to the next step in the flow.
   */
  const gotoNextStep = async () => {
    if (receipt.value) receipt.value.close();

    try {
      const validated = await flowStore.validateStep(navStore.currentRouteValidator);
      if (validated) return await skipEmptyUpsellStepsAndNavigate(true);
    } catch (error) {
      console.warn('Validation checkout failed.', error);
    }
  };

  /**
   * Navigate to the previous step in the flow.
   */
  const gotoPreviousStep = async () => {
    return await skipEmptyUpsellStepsAndNavigate(false);
  };

  /**
   * Create a booking.
   */
  const createBooking = async () => {
    if (receipt.value) receipt.value.close();

    try {
      const booking = await flowStore.createBooking();
      if (booking) {
        // Reset the remebered path, so we don't return
        // to the personal details screen after the booking is started.
        navStore.resetRememberedPath();

        sendMessage('reservationflow', 'redirect', { tag: 'START_PAYMENT', url: booking.redirect_url });
        // sendMessage('reservationflow', 'reservationflow.close');
        if (!isIntergrated) window.location.href = booking.redirect_url;
      }
    } catch (error) {
      console.warn('Creating a booking is cancelled due server or validation errors.');

      if (isAxiosError(error) && error.response && error.response?.data?.errors) {
        const messages: Record<string, string[]> = error.response.data.errors;
        const messageKeys = Object.keys(messages);

        const reservationViewErrorKeys = [
          'arrival_time',
          'departure_time',
          'arrival_date_and_time',
          'arrangement_choices.room_products',
          'arrangement_choices.primary_products',
          'arrangement_choices.secondary_products',
        ];

        // Check if there are validation errors in the reservation step, then navigate to that step.
        if (reservationViewErrorKeys.some(key => messageKeys.includes(key))) {
          await navStore.gotoFirstStep();
        }
      }
    }
  };
</script>
