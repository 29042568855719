<template>
  <div data-cy="person_choice" v-show="!optimisticRemove" :class="{
    'flex justify-between gap-2 py-2 px-4 rounded text-qwr-dark-600': true,
    'group/cartitem hover:bg-qwr-haze-50 hover:text-qwr-dark-900 focus-within:bg-qwr-haze-50 focus-within:text-qwr-dark-900': item.removable,
  }">
    <div class="flex items-start gap-2 relative pl-8">

      <div v-if="item.icon" class="text-base text-qwr-haze-200 absolute -left-1 group-hover/cartitem:hidden group-focus/cartitem:hidden group-focus-within/cartitem:hidden">
        <CalendarIcon v-if="item.icon === 'icon-calendar-svg'" class="h-6" />
        <ClockIcon v-else-if="item.icon === 'icon-clock-svg'" class="h-6" />
        <CoupleIcon v-else-if="item.icon === 'icon-couple-svg'" class="h-6" />
        <FoodIcon v-else-if="item.icon === 'icon-food-svg'" class="h-6" />
        <ListIcon v-else-if="item.icon === 'icon-list-svg'" class="h-6" />

        <CoffeeIcon v-else-if="item.icon === 'icon-coffee-svg'" class="h-6" />
        <CoffeeBerendonckIcon v-else-if="item.icon === 'icon-coffee-svg-berendonck'" class="h-6" />
        <CoffeeBusslooIcon v-else-if="item.icon === 'icon-coffee-svg-bussloo'" class="h-6" />
        <CoffeeNieuweschansIcon v-else-if="item.icon === 'icon-coffee-svg-nieuweschans'" class="h-6" />
        <CoffeeSoesterbergIcon v-else-if="item.icon === 'icon-coffee-svg-soesterberg'" class="h-6" />

        <TowelIcon v-else-if="item.icon === 'icon-towel-svg'" class="h-6" />
        <TowelBerendonckIcon v-else-if="item.icon === 'icon-towel-svg-berendonck'" class="h-6" />
        <TowelBusslooIcon v-else-if="item.icon === 'icon-towel-svg-bussloo'" class="h-6" />
        <TowelNieuweschansIcon v-else-if="item.icon === 'icon-towel-svg-nieuweschans'" class="h-6" />
        <TowelSoesterbergIcon v-else-if="item.icon === 'icon-towel-svg-soesterberg'" class="h-6" />
        <i v-else-if="item.icon" :class="item.icon" />
      </div>

      <button v-if="item.removable" @click="handleRemoveItem(person_id, item.slot, item.item_id)" type="button" class="flex w-6 h-6 rounded-full bg-qwr-dark-500 text-white justify-center items-center absolute -left-1 transition-opacity lg:opacity-0 group-hover/cartitem:opacity-100 group-focus/cartitem:opacity-100 group-focus-within/cartitem:opacity-100 focus:outline-hidden">
        <i class="icon-kruis" />
      </button>
      {{ item.name }}
    </div>
    <Price :value="item.price" />
  </div>
</template>

<script lang="ts" setup>
  import { ref } from 'vue';
  import type { ReceiptProductResource } from '@/api/resources';
  import { useFlowStore } from '@/stores/useFlowStore';
  import useTransaction from '@/compositions/useTransaction';
  import Price from '@/components/Price.vue';

  import CalendarIcon from '@/theme/icons/calendar.svg?component';
  import ClockIcon from '@/theme/icons/clock.svg?component';
  import CoupleIcon from '@/theme/icons/couple.svg?component';
  import FoodIcon from '@/theme/icons/food.svg?component';
  import ListIcon from '@/theme/icons/list.svg?component';

  import CoffeeIcon from '@/theme/icons/coffee.svg?component';
  import CoffeeBerendonckIcon from '@/theme/icons/coffee-berendonck.svg?component';
  import CoffeeBusslooIcon from '@/theme/icons/coffee-bussloo.svg?component';
  import CoffeeNieuweschansIcon from '@/theme/icons/coffee-nieuweschans.svg?component';
  import CoffeeSoesterbergIcon from '@/theme/icons/coffee-soesterberg.svg?component';

  import TowelIcon from '@/theme/icons/towel.svg?component';
  import TowelBerendonckIcon from '@/theme/icons/towel-berendonck.svg?component';
  import TowelBusslooIcon from '@/theme/icons/towel-bussloo.svg?component';
  import TowelNieuweschansIcon from '@/theme/icons/towel-nieuweschans.svg?component';
  import TowelSoesterbergIcon from '@/theme/icons/towel-soesterberg.svg?component';

  export interface Props {
    person_id: number,
    item: ReceiptProductResource
  }

  defineProps<Props>();

  const { deletePersonChoice } = useFlowStore();
  const { requestPatch } = useTransaction();

  const optimisticRemove = ref(false);

  const handleRemoveItem = async (personId: number, slot: string, productId: number) => {
    optimisticRemove.value = true;
    requestPatch();
    await deletePersonChoice(personId, slot, productId);
  };
</script>
