<template>
  <div class="grid 2xs:grid-cols-2 gap-4 items-center mt-6">
    <label for="arrivalTime" class="pr-4">
      {{ $t('estimated_time_of_arrival') }}
      <Tooltip class="ml-2">{{ $t('arrival_and_departure_time_explanation') }}</Tooltip>
    </label>
    <SelectTimeField
      align="center"
      variant="outlined"
      id="arrivalTime"
      data-cy="select-arrival-time"
      :disabled="$loadingstore.changing_date"
      :times="filteredArrivalTimes"
      :errors="$validationstore.errors.arrival_time"
      :modelValue="$flowstore.checkout.data.arrival.time"
      @update:modelValue="changeArrivalTime($event)"
    />
  </div>

  <div class="grid 2xs:grid-cols-2 gap-4 items-center mt-4">
    <label for="departureTime" class="pr-4">
      {{ $t('estimated_time_of_departure') }}
      <Tooltip class="ml-2">{{ $t('arrival_and_departure_time_explanation') }}</Tooltip>
    </label>
    <SelectTimeField
      align="center"
      variant="outlined"
      id="departureTime"
      data-cy="select-departure-time"
      :disabled="$loadingstore.changing_date || $flowstore.checkout.data.arrival.time === null"
      :times="filteredDepartureTimes"
      :errors="$validationstore.errors.departure_time"
      :modelValue="$flowstore.checkout.data.departure.time"
      @update:modelValue="flowStore.setCheckoutTime('departure', $event)"
    />
  </div>

  <Transition name="fade">
    <NoteBlock v-if="$validationstore.errors.arrival_date_and_time" variant="error" class="my-6 error-scroll-anchor">
      {{ $validationstore.errors.arrival_date_and_time[0] }}
    </NoteBlock>
  </Transition>

  <Transition name="fade">
    <NoteBlock v-if="$validationstore.errors.product_choices" variant="error" class="my-6 error-scroll-anchor">
      {{ $validationstore.errors.product_choices[0] }}
    </NoteBlock>
  </Transition>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useFlowStore } from '@/stores/useFlowStore';
import NoteBlock from '@/components/NoteBlock.vue';
import SelectTimeField from '@/components/fields/SelectTimeField.vue';
import Tooltip from '@/components/Tooltip.vue';

const flowStore = useFlowStore();

async function changeArrivalTime(arrivalTime: string) {
  if (!flowStore.checkout.meta.departure_times) return;

  const departureTimesEntries = Object.entries(flowStore.checkout.meta.departure_times);
  const arrivalTimeIndex = departureTimesEntries.findIndex(([key]) => key === arrivalTime);
  const departureTimeIndex = departureTimesEntries.findIndex(([key]) => key === flowStore.checkout.data.departure.time);

  if (departureTimeIndex >= 0 && arrivalTimeIndex >= departureTimeIndex) {
    const nextAvailableDepartureTime = (arrivalTimeIndex + 1) < departureTimesEntries.length
      ? departureTimesEntries[arrivalTimeIndex + 1][0]
      : departureTimesEntries[departureTimesEntries.length - 1][0];

    if (nextAvailableDepartureTime) {
      return await flowStore.setCheckoutTimes(arrivalTime, nextAvailableDepartureTime);
    }
  }

  await flowStore.setCheckoutTime('arrival', arrivalTime);
}

const filteredArrivalTimes = computed(() => {
  if (!flowStore.checkout.meta.arrival_times) return {};

  const arrivalTimesEntries = Object.entries(flowStore.checkout.meta.arrival_times);

  const arrivalTimesFilteredEntries = arrivalTimesEntries.filter(([_time, available]) => {
    return available === true;
  });

  return Object.fromEntries(arrivalTimesFilteredEntries);
});

const filteredDepartureTimes = computed(() => {
  if (!flowStore.checkout.meta.departure_times) return {};

  const departureTimesEntries = Object.entries(flowStore.checkout.meta.departure_times);
  const departureTimeIndex = departureTimesEntries.findIndex(([key]) => key === flowStore.checkout.data.arrival.time);

  const departureTimesFilteredEntries = departureTimesEntries.filter(([_time, available], index) => {
    return available === true && (!departureTimeIndex || index > departureTimeIndex || index === departureTimesEntries.length - 1)
  });

  return Object.fromEntries(departureTimesFilteredEntries);
});
</script>
