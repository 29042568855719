import type {MessageSchema} from '@/translations';

const messages: MessageSchema = {
  a_few_more_details: 'Encore quelques détails',
  accept_mailinglist_sentence: 'Je m\'inscris aux mailings de Thermen {resort} pour recevoir des nouvelles et des offres personnalisées',
  accept_retreats_mailinglist_sentence: 'Je m\'inscris aux mailings de Retreats pour recevoir des nouvelles et des offres personnalisées',
  accept_terms_sentence: 'Par la présente, je confirme accepter les conditions suivantes : {terms_list}',
  accept_thermenfans_sentence: 'Je m\'inscris au {link} et bénéficie ainsi d\'avantages exclusifs tels que : offres, bons de réduction personnalisés, cadeaux sympas et accès exclusif aux événements',
  add_one: 'Ajouter',
  all_choices: 'Tous les choix',
  amount_of_rooms: "{n} chambre | {n} chambres",
  an_email_will_be_sent_to_your_email_if_you_press_reset_password_this_email_will_contain_a_link_to_request_a_new_password: 'Un e-mail sera envoyé à votre adresse e-mail si vous appuyez sur réinitialiser le mot de passe. Cet e-mail contiendra un lien pour demander un nouveau mot de passe',
  are_you_coming_together_but_still_want_to_enjoy_your_own_room: 'Vous venez ensemble, mais vous souhaitez tout de même profiter d\'une chambre privée ? Pas de problème. Indiquez simplement votre préférence lors de la réservation, et nous nous assurerons que tout est organisé selon vos souhaits. Ainsi, chacun pourra vivre son moment ultime de détente.',
  are_you_sure_you_want_to_reset_your_password: 'Êtes-vous sûr de vouloir réinitialiser votre mot de passe ?',
  arrangement_choice_nr: 'Choix d\'arrangement {n}',
  arrangement_choices: 'Arrangement @:choices',
  arrival: 'Arrivée',
  arrival_and_departure_time_explanation: 'Pour une visite agréable, nous vous demandons d\'arriver et de partir aux horaires de votre réservation. De cette façon, nous maintenons ensemble un bon équilibre dans le nombre de clients qui effectuent leur check-in et check-out.',
  belgium: 'Belgique',
  business_name: 'Nom de l\'entreprise',
  cancellation_policy: 'Conditions d\'annulation',
  choices: 'choix | choix',
  choose_arrangement: 'Faites-en vraiment votre arrangement. Choisissez votre favori parmi les options ci-dessous pour votre journée bien-être.',
  choose_your_arrival_and_departure_day_in_the_calendar: 'Choisissez dans le calendrier votre jour d\'arrivée et de départ',
  city: 'Ville',
  close: 'Fermer',
  comments_or_extra_information: 'Commentaires / informations supplémentaires',
  country: 'Pays',
  customer_page_into_sentence: 'Nous avons besoin d\'un peu plus d\'informations de votre part. Juste un peu. Et bien sûr, nous gardons vos données pour nous. Promis.',
  date_of_birth: 'Date de naissance',
  day: 'Jour',
  departure: 'Départ',
  edit: "Modifier",
  email_address: 'Adresse e-mail',
  estimated_time_of_arrival: 'Heure d\'arrivée prévue',
  estimated_time_of_departure: 'Heure de départ prévue',
  evening: 'Soir',
  extra_charge: 'Supplément',
  filter: 'filtrer',
  first_name: 'Prénom',
  fold: "Replier",
  for_who: 'Pour qui ?',
  forgot_your_password_no_problem: 'Mot de passe oublié ? Pas de problème.',
  from_price: "à p. de",
  germany: 'Allemagne',
  go_to_the_website: 'Aller sur le site web',
  hide: 'Masquer les',
  hide_all_single_rooms: 'Masquer toutes les chambres simples',
  hotel_room_availability: 'Encore {number} disponible',
  hotel_room_choice: 'Choix de la chambre d\'hôtel',
  hotel_room_type_1: 'chambre simple',
  hotel_room_type_2: 'chambre double',
  hotel_room_type_3: 'chambre triple',
  hotel_room_type_4: 'chambre quadruple',
  hotel_rooms: 'Chambres d\'hôtel',
  hotel_rooms_page_into_sentence: 'Vous venez passer la nuit chez nous ?',
  house_number: 'Numéro de maison',
  house_number_addition: 'Ajout',
  house_rules: 'règlement intérieur',
  how_long: 'Combien de temps ?',
  how_would_you_like_to_book_your_relaxing_moment: 'Comment souhaitez-vous réserver votre moment de détente ?',
  i_have_a_my_thermen_account: 'J\'ai un compte Thermen Resorts',
  i_want_to_create_a_my_thermen_account: 'Je souhaite créer un compte Thermen Resorts',
  i_want_to_create_a_my_thermen_account_tooltip: 'Réservez plus rapidement et ne saisissez plus jamais vos données. Un compte Thermen Resorts vous offre plus. Plus de commodité et plus d\'avantages. Attention ! Votre compte n\'est activé qu\'en cliquant sur le lien dans l\'e-mail de vérification.',
  i_want_to_make_a_reservation_without_an_account: 'Je veux réserver sans compte',
  i_would_like_to_create_an_account: 'Je souhaite créer un compte',
  invoice_comment_placeholder: 'Commentaires/détails (par exemple numéro d\'achat)',
  invoice_information: 'Détails de la facture employeur',
  last_name: 'Nom de famille',
  login: 'Connexion',
  logout: 'Déconnexion',
  lunch_and_diner: 'Déjeuner et dîner',
  lunch_and_diner_page_into_sentence: 'Votre journée bien-être encore plus complète et encore plus agréable ? Découvrez nos séances de versement, rituels, méditations et autres moments de détente agréables... Notre conseil ? Choisissez surtout ce qui vous rend heureux !',
  make_it_your_day: 'Faites-en votre journée !',
  mobile_phone_number: 'Numéro de téléphone portable',
  month: 'Mois',
  more_info: 'Plus d\'info',
  more_information: 'Plus d\'informations',
  morning: 'Matin',
  mr: 'Monsieur',
  ms: 'Madame',
  my_preferences_for_a_room_are: 'Mes préférences pour une chambre sont',
  name_infix: 'Particule',
  need_help_with_your_room_choices: 'Besoin d\'aide pour choisir votre chambre ?',
  next: 'Suivant',
  no_extra_charge: 'Sans supplément',
  no_rooms_available_selected_date_filters: "Pour la date actuellement sélectionnée, en combinaison avec les filtres sélectionnés, aucune chambre n'est disponible.",
  notice_chosen_date_or_time_is_unavailable: 'La date et/ou l\'heure choisies ne sont pas disponibles !',
  notice_there_are_no_hotel_rooms_unavailable: 'Aucune chambre disponible à la date choisie avec les filtres sélectionnés.',
  notice_this_hotel_room_is_unavailable: 'Cette chambre n\'est plus disponible pour le jour choisi !',
  number_of_persons: '{n} personne | {n} personnes',
  or_stay_with_us_for_a_good_night_sleep: 'Ou restez-vous dormir agréablement ?',
  other: 'Autre',
  password: 'Mot de passe',
  password_confirmation: 'Confirmer le mot de passe',
  pay: 'Payer',
  per_night: 'par nuit',
  per_person_including_breakfast_starting_from: 'p.p. incluant le petit-déjeuner à partir de',
  per_person_per_night: 'p.p.p.n.',
  per_person_short: 'p.p.',
  persons: 'personne | personnes',
  persons_count: 'Nombre de personnes',
  previous: 'Précédent',
  prices_are_in_euro: 'Les prix sont en € @:per_person_short',
  privacy_policy: 'Politique de confidentialité',
  receive_a_present_on_your_birthday: 'Recevoir un cadeau pour votre anniversaire ?',
  reload: 'Recharger',
  request_your_new_password_here: 'Demandez votre nouveau mot de passe ici.',
  reset: 'Réinitialiser',
  reset_filters: 'Réinitialiser les filtres',
  reset_password: 'Réinitialiser le mot de passe',
  rituals_extras: 'Extras pour votre journée au sauna',
  rituals_extras_page_into_sentence: 'Votre journée bien-être encore plus complète et encore plus agréable ? Découvrez nos séances de versement, rituels, méditations et autres moments de détente agréables... Notre conseil ? Choisissez surtout ce qui vous rend heureux !',
  rooms_count: "Nombre de chambres",
  select: 'Sélectionner',
  select_all: 'Sélectionner tout',
  select_for_all: 'Sélectionner pour tout le monde',
  selected: 'Sélectionné',
  send_invoice: 'Mon employeur paie la facture.',
  share_a_room_together_select_one_of_the_available_room_options_below: 'Partagez une chambre ensemble, sélectionnez l\'une des options de chambre disponibles ci-dessous.',
  show_all_single_rooms: 'Afficher toutes les chambres simples',
  something_went_wrong_while_retrieving_your_data: 'Un problème est survenu lors de la récupération de vos données.',
  steps: {
    choices: 'Vos choix',
    complete: 'Terminer',
    customer_details: 'Détails du client',
    reservation: 'Réservation'
  },
  street: 'Rue',
  swimwear_day: 'Jour de maillot de bain',
  swimwear_day_tooltip_sentence: 'Les jours marqués en bleu dans le calendrier sont des jours de maillot de bain. Le port du maillot de bain est obligatoire ces jours-là.',
  terms_and_conditions: 'Conditions générales',
  terms_item_1: 'Mon heure d\'arrivée et de départ (afin que les éventuels traitements puissent avoir lieu dans la demi-heure suivant l\'heure d\'arrivée indiquée et avant l\'heure de départ indiquée)',
  terms_item_2: 'J\'ai lu le {house_rules}',
  terms_item_3: 'Les {terms_and_conditions}, les {cancellation_policy} et la {privacy_policy}',
  the_netherlands: 'Pays-Bas',
  there_are_no_selectable_products_available_at_the_selected_time: 'Il n\'y a aucun produit sélectionnable disponible au moment choisi.',
  thermenfans_program: 'Programme Thermenfans',
  total: 'Total',
  treatment_page_into_sentence: 'Votre journée chez nous peut être encore plus complète. Un massage ? Un traitement ? Notre conseil : choisissez ce qui vous rend heureux, c\'est toujours le meilleur choix.',
  unavailable: 'Indisponible',
  view: 'Voir la',
  view_reservation: 'Voir la réservation',
  we_put_together_the_available_options_for_you: 'Nous rassemblons les options disponibles pour vous...',
  what_would_you_like_to_book: 'Que souhaitez-vous réserver ?',
  when_are_you_coming_to_relax: 'Quand viendrez-vous vous détendre ?',
  when_resetting_previously_entered_data_will_be_lost: 'En réinitialisant, les données saisies précédemment seront perdues.',
  which_type_of_treatment_suits_you: 'Quel type de traitement vous convient ?',
  year: 'Année',
  you_have: 'Vous avez',
  you_have_number_of_choices: 'Vous avez <strong>{n}</strong> @:choices',
  you_want_to_book_with: 'Vous voulez réserver avec <strong>{persons}</strong> et souhaitez <strong>{rooms}</strong> avec une date d\'arrivée {arrival_date} et une date de départ {departure_date}.',
  your_experience: 'Voici ce que vous allez vivre :',
  your_first_step_to_great_enjoyment: 'Votre premier pas vers un grand plaisir !',
  zip_code: 'Code postal'
};

export default messages;
