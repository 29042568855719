<template>
  <label class="inline-flex gap-2">
    <input
      type="radio"
      :id="`radiofield_${uid}`"
      :name="group"
      :checked="isChecked"
      :disabled="disabled"
      @input="commitChanges"
      :class="{
        'flex-none align-top appearance-none w-4 h-4 rounded-full transition-all border checked:border-primary checked:border-4': true,
        'border-qwr-dark-200': !hasError,
        'border-red-600': hasError,
      }"
    />
    <div v-if="slots.default" :class="{
      '-mt-1': true,
      'text-red-600 [&>a]:text-red-600': hasError,
    }"><slot /></div>
  </label>
</template>

<script lang="ts" setup>
  import { computed, getCurrentInstance, ref, watch } from 'vue';

  export type Scalar = string | boolean | number | null;

  export interface Props {
    modelValue?: Scalar
    onValue?: Scalar
    group?: string
    disabled?: boolean
    errors?: string[]
  }

  const props = withDefaults(defineProps<Props>(), {
    onValue: true,
    group: 'group',
    disabled: false,
  });

  const slots = defineSlots();

  const emit = defineEmits([
    'update:modelValue',
  ]);

  const uid = getCurrentInstance()?.uid

  const isChanged = ref(false);

  const hasError = computed(() => props.errors && props.errors.length > 0 && !isChanged.value);

  watch(() => props.errors, () => {
    isChanged.value = false;
  });

  const commitChanges = (event: Event) => {
    isChanged.value = true;
    emit('update:modelValue', props.onValue);
  };

  const isChecked = computed(() => {
    return props.modelValue === props.onValue;
  });
</script>
