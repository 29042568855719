<template>
  <Card class="flex flex-col justify-between w-full">
    <div class="relative">
      <div v-if="product.is_available && product.slider.length" class="relative rounded-t aspect-[2/1] overflow-hidden bg-black">
        <Slider :settings="{ items: 1, nav: true, loop: true }">
          <div v-for="media in product.slider" class="aspect-[2/1]">
            <Image v-if="media.type === 'image'" :src="media.data.url" class="object-cover object-center h-full w-full" />
            <iframe v-if="media.type === 'youtube_embed'" :src="media.data.url" class="object-cover object-center h-full w-full" width="560" height="315" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
        </Slider>
        <div class="absolute left-6 top-6" v-if="product.available_rooms && product.available_rooms < 5">
          <div class="rounded-full bg-[#b79f56] text-white leading-7 text-xs px-4 uppercase tracking-wide">
            {{ $t('hotel_room_availability', {number: product.available_rooms}) }}
          </div>
        </div>
      </div>
      <div class="px-6 pt-6 pb-0">
        <p class="uppercase text-primary text-sm mb-1 font-semibold tracking-wide">{{ $t('hotel_room_type_' + (product.required_number_of_persons || 1)) }}</p>
        <h3 class="text-xl font-serif mb-0">{{ product.name }}</h3>
      </div>
      <div class="relative px-6 pb-6 pt-3">
        <HtmlContent :html="product.description" strip class="mt-2 mb-4 text-sm" />

        <UspList v-if="product.usps" :usps="product.usps.slice(0, 3)" gap="gap-2" autoflow class="mb-6" />

        <button type="button" @click="modal?.openModal()" class="flex items-center uppercase text-sm font-semibold tracking-wider">
          <HelpCircle class="mr-2 w-3.5" />
          {{ $t('more_information') }}
        </button>
        <div v-if="!product.is_available" class="absolute inset-0 p-4 flex items-center justify-center backdrop-blur-[2px] bg-stone-100/50">
          <span class="bg-white text-xs px-4 py-3 rounded-full shadow-md">{{ $t('notice_this_hotel_room_is_unavailable') }}</span>
        </div>
      </div>
    </div>
    <div class="relative">
      <ProductPersonSelection :group="group" :product="product" v-if="!shouldHidePersonSelection" class="p-6 border-t border-qwr-haze-100" />
      <footer class="px-6 py-4 border-t border-qwr-haze-100">
        <div class="flex flex-col lg:flex-row space-y-2 lg:space-y-0 justify-between items-start lg:items-center">
          <Price v-if="!checkout.meta.show_hotel_room_categories || product.raw_price" :value="product.price" prefix="+" :affix="$t('per_person_per_night')" class="text-2xl font-semibold block" />
          <span v-if="checkout.meta.show_hotel_room_categories && !product.raw_price" class="text-2xl font-semibold block">{{ $t('no_extra_charge') }}</span>
          <Button variant="light" class="w-full lg:w-auto" @click="selectAllPersons">
            <div class="text-center flex gap-4" v-if="shouldHidePersonSelection">
              <LoadingIndicator :size="20" v-if="loading" />

              <template v-if="isSelectedForAllPersons">
                <i class="icon-ok" />
              </template>

              <template v-else>
                <template v-if="loading">
                  <template class="hidden lg:block">
                    {{ $t('select') }}
                  </template>
                </template>

                <template v-else>
                  {{ $t('select') }}
                </template>
              </template>
            </div>

            <span v-else>
              <span class="hidden lg:block">{{$t('select_all') }}</span>
              <span class="lg:hidden">{{ $t('select') }}</span>
            </span>
          </Button>
        </div>
      </footer>
    </div>

    <ProductDetailModal :product="product" :group="group" ref="modal" :is-loading="loading" :hide-person-selection="shouldHidePersonSelection" :is-selected="isSelectedForAllPersons" @select="handleSelectFromModal" />
  </Card>
</template>

<script lang="ts" setup>
  import { isEqual, cloneDeep } from 'lodash';
  import {ref, watch, onMounted, computed} from 'vue';
  import { useFlowStore } from '@/stores/useFlowStore';
  import Button from '@/components/Button.vue';
  import Card from '@/components/Card.vue';
  import HelpCircle from '@/theme/icons/help-circle.svg?component';
  import HtmlContent from '@/components/HtmlContent.vue';
  import Image from '@/components/Image.vue';
  import Price from '@/components/Price.vue';
  import ProductDetailModal from '@/components/modals/ProductDetailModal.vue';
  import ProductPersonSelection from '@/components/ProductPersonSelection.vue';
  import Slider from '@/components/Slider.vue';
  import type { UpsellProductResource } from '@/api/resources';
  import useTransaction from '@/compositions/useTransaction';
  import UspList from "@/components/UspList.vue";
  import LoadingIndicator from "@/components/LoadingIndicator.vue";

  export interface Props {
    group: string,
    product: UpsellProductResource
  }

  const props = defineProps<Props>();

  const { setPersonChoices, checkout } = useFlowStore();
  const { requestPatch } = useTransaction(); // NOTE: Mogelijk overbodig geworden

  const persons = ref<{
    id: number
    name: string
    selected: boolean
  }[]>([]);

  const modal = ref<InstanceType<typeof ProductDetailModal>>()
  const loading = ref(false)
  const shouldHidePersonSelection = computed(() => checkout.data.checkout_type === 'hotel-arrangement' && checkout.data.amount_of_rooms === 1)
  const isSelectedForAllPersons = computed(() => props.product.persons?.every(person => person.selected));

  onMounted(() => {
    if (props.product.persons) {
      persons.value = cloneDeep(props.product.persons);
    }
  });

  watch(() => props.product.persons, (newValue, oldValue) => {
    if (newValue && !isEqual(oldValue, newValue)) {
      persons.value = cloneDeep(newValue);
    }
  }, { deep: true });

  const commitChanges = async () => {
    const selectedPersonIds: number[] = persons.value.filter(person => person.selected).map(person => person.id);

    if (props.product.id && props.product.is_available) {
      requestPatch();
      await setPersonChoices(selectedPersonIds, props.product.slot, props.product.id);
    }

    loading.value = false;
  };

  const selectAllPersons = async () => {
    if (props.product.persons) {
      loading.value = true;

      if(shouldHidePersonSelection.value && isSelectedForAllPersons.value) {
        persons.value.forEach((person, index) => {
          person.selected = false;
        });
      } else {
        persons.value.forEach((person, index) => {
          person.selected = true;
        });
      }
    }
    await commitChanges();
  };

  const handleSelectFromModal = async () => {
    if(!shouldHidePersonSelection.value || !isSelectedForAllPersons.value) {
      modal.value?.closeModal();
    }
    await selectAllPersons();
  };
</script>
