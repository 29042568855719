<template>
  <div v-if="checkout.meta.voucher_codes?.length" class="py-6 px-4 border-t border-gray-200">
    <div v-for="voucher_code in checkout.meta.voucher_codes" :key="voucher_code" class="flex justify-between gap-2 py-2 px-4 rounded text-qwr-dark-600">
      <div class="flex items-start gap-2 relative pl-8">
        <div class="text-base text-qwr-haze-200 absolute -left-1 group-hover/cartitem:hidden group-focus/cartitem:hidden group-focus-within/cartitem:hidden">
          <RecipeIcon class="h-6" />
        </div>
        Voucher
      </div>
      {{ voucher_code }}
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { useFlowStore } from '@/stores/useFlowStore';
  import RecipeIcon from '@/theme/icons/recipe.svg?component';

  const { checkout } = useFlowStore();
</script>
