<template>
  <img :src="src ? src : fallback" :alt="alt" :loading="lazy ? 'lazy' : undefined" @error.once="handleError" />
</template>

<script lang="ts" setup>
  import fallbackImage from '@/theme/assets/fallback.jpg';

  export type Props = {
    src?: string | null
    alt?: string
    fallback?: string
    lazy?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    alt: 'photo',
    fallback: fallbackImage,
    lazy: true,
  });

  const handleError = (payload: Event) => {
    (payload.target as HTMLImageElement).src = props.fallback;
  };
</script>
