<template>
  <Transition name="modal">
    <div v-if="isOpened" class="bg-white fixed inset-x-0 top-0 bottom-[4.3rem] lg:hidden overflow-auto">
      <header class="relative">
        <div v-if="$flowstore.checkout.meta.product.image" class="h-48 overflow-hidden">
          <Image :src="$flowstore.checkout.meta.product.image" class="h-full w-full object-cover object-center" />
        </div>
        <button type="button" @click="close" class="absolute bg-white rounded-full right-6 top-8 flex w-10 h-10 items-center justify-center text-qwr-dark-400 active:text-qwr-dark">
          <Cross class="w-4 h-4 pointer-events-none" />
        </button>
      </header>
      <div class="py-6 px-8">
        <h1 :class="!$flowstore.checkout.meta.product.image ? 'mr-12' : ''">{{ $flowstore.checkout.meta.product.name }}</h1>
        <HtmlContent :html="$flowstore.checkout.meta.product.description" class="text-qwr-haze-600 pb-4" />
        <template v-if="$flowstore.checkout.meta.usps && $flowstore.checkout.meta.usps.length > 0">
          <p v-if="!$flowstore.checkout.meta.product.description" class="text-gray-500 pb-4 text-sm">{{ $t('your_experience') }}</p>
          <UspList :usps="$flowstore.checkout.meta.usps" />
        </template>
      </div>
      <ArrivalInfo />
      <PersonChoices />
      <AdditionalCosts />
      <VoucherInfo />
      <Upsells />
    </div>
  </Transition>
</template>

<script lang="ts" setup>
  import { ref } from 'vue';
  import AdditionalCosts from '@/components/receipt/AdditionalCosts.vue';
  import ArrivalInfo from '@/components/receipt/ArrivalInfo.vue';
  import Cross from '@/theme/icons/cross.svg?component';
  import PersonChoices from '@/components/receipt/PersonChoices.vue';
  import Upsells from '@/components/receipt/Upsells.vue';
  import VoucherInfo from '@/components/receipt/VoucherInfo.vue';
  import HtmlContent from "@/components/HtmlContent.vue";
  import UspList from "@/components/UspList.vue";
  import Image from "@/components/Image.vue";

  const emit = defineEmits([
    'close',
    'open',
  ]);

  const isOpened = ref(false);

  const open = () => {
    isOpened.value = true;
    emit('open');
  };

  const close = () => {
    emit('close');
    isOpened.value = false;
  };

  const toggle = () => {
    if (isOpened.value) {
      close();
    } else {
      open();
    }
  };

  defineExpose({
    open,
    close,
    toggle,
  });
</script>
