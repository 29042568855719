<template>
  <div v-if="!hidden" class="flex items-center gap-4 justify-between py-2 px-3 my-6 mx-8 rounded border border-gray-200">
    <TowelIcon class="flex-none fill-primary h-6" />
    <p>{{ product.name }} (<Price :value="product.price" />)</p>
    <div class="flex-none flex gap-2 items-center">
      <Tooltip align="right" v-if="product.description">{{ product.description }}</Tooltip>
      <Button variant="primary" :data-cy="`upsell-receipt-card-button-${product.id}`" @click="commitChanges">{{ $t('add_one') }}</Button>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import Button from '@/components/Button.vue';
  import Price from '@/components/Price.vue';
  import type { UpsellProductResource } from '@/api/resources';
  import { useFlowStore } from '@/stores/useFlowStore';
  import { ref, onMounted, onUpdated } from 'vue';
  import Tooltip from '@/components/Tooltip.vue';
  import TowelIcon from '@/theme/icons/towel.svg?component';

  export interface Props {
    product: UpsellProductResource
  }

  const props = defineProps<Props>();

  const { setPersonChoices } = useFlowStore();

  const personIds = ref<boolean[]>([]);
  const hidden = ref(false);

  onMounted(() => {
    if (props.product.persons) {
      props.product.persons.forEach(x => {
        personIds.value[x.id] = true;
      });

      hidden.value = props.product.persons.every(x => x.selected) || !props.product.is_available;
    }
  });

  onUpdated(() => {
    if (props.product.persons) {
      props.product.persons.forEach(x => {
        personIds.value[x.id] = true;
      });

      hidden.value = props.product.persons.every(x => x.selected) || !props.product.is_available;
    }
  });

  const commitChanges = async () => {
    const selectedPersonIds: number[] = [];

    personIds.value.forEach((selected, id) => {
      if (selected) selectedPersonIds.push(id);
    });

    if (props.product.id) {
      await setPersonChoices(selectedPersonIds, props.product.slot, props.product.id);
    }
  };
</script>
