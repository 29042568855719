<template>
  <div v-if="items.length > 0" class="py-6 px-4 border-t border-gray-200">
    <div v-for="item in items" :key="item.name" class="flex justify-between gap-2 py-2 px-4 rounded text-qwr-dark-600">
      <div class="flex items-start gap-2 relative pl-8">
        <div v-if="item.icon" class="text-base text-qwr-haze-200 absolute -left-1 group-hover/cartitem:hidden group-focus/cartitem:hidden group-focus-within/cartitem:hidden">
          <CalendarIcon v-if="item.icon === 'icon-calendar-svg'" class="h-6" />
          <ClockIcon v-else-if="item.icon === 'icon-clock-svg'" class="h-6" />
          <CoupleIcon v-else-if="item.icon === 'icon-couple-svg'" class="h-6" />
          <FoodIcon v-else-if="item.icon === 'icon-food-svg'" class="h-6" />
          <ListIcon v-else-if="item.icon === 'icon-list-svg'" class="h-6" />
          <CoffeeIcon v-else-if="item.icon === 'icon-coffee-svg'" class="h-6" />
          <CoffeeBerendonckIcon v-else-if="item.icon === 'icon-coffee-svg-berendonck'" class="h-6" />
          <CoffeeBusslooIcon v-else-if="item.icon === 'icon-coffee-svg-bussloo'" class="h-6" />
          <CoffeeNieuweschansIcon v-else-if="item.icon === 'icon-coffee-svg-nieuweschans'" class="h-6" />
          <CoffeeSoesterbergIcon v-else-if="item.icon === 'icon-coffee-svg-soesterberg'" class="h-6" />
          <TowelIcon v-else-if="item.icon === 'icon-towel-svg'" class="h-6" />
          <TowelBerendonckIcon v-else-if="item.icon === 'icon-towel-svg-berendonck'" class="h-6" />
          <TowelBusslooIcon v-else-if="item.icon === 'icon-towel-svg-bussloo'" class="h-6" />
          <TowelNieuweschansIcon v-else-if="item.icon === 'icon-towel-svg-nieuweschans'" class="h-6" />
          <TowelSoesterbergIcon v-else-if="item.icon === 'icon-towel-svg-soesterberg'" class="h-6" />
          <i v-else-if="item.icon" :class="item.icon" />
        </div>
        {{ item.name }}
      </div>
      <Price :value="item.price" />
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { computed } from 'vue';
  import { useFlowStore } from '@/stores/useFlowStore';
  import Price from '@/components/Price.vue';

  import CalendarIcon from '@/theme/icons/calendar.svg?component';
  import ClockIcon from '@/theme/icons/clock.svg?component';
  import CoupleIcon from '@/theme/icons/couple.svg?component';
  import FoodIcon from '@/theme/icons/food.svg?component';
  import ListIcon from '@/theme/icons/list.svg?component';

  import CoffeeIcon from '@/theme/icons/coffee.svg?component';
  import CoffeeBerendonckIcon from '@/theme/icons/coffee-berendonck.svg?component';
  import CoffeeBusslooIcon from '@/theme/icons/coffee-bussloo.svg?component';
  import CoffeeNieuweschansIcon from '@/theme/icons/coffee-nieuweschans.svg?component';
  import CoffeeSoesterbergIcon from '@/theme/icons/coffee-soesterberg.svg?component';

  import TowelIcon from '@/theme/icons/towel.svg?component';
  import TowelBerendonckIcon from '@/theme/icons/towel-berendonck.svg?component';
  import TowelBusslooIcon from '@/theme/icons/towel-bussloo.svg?component';
  import TowelNieuweschansIcon from '@/theme/icons/towel-nieuweschans.svg?component';
  import TowelSoesterbergIcon from '@/theme/icons/towel-soesterberg.svg?component';

  export type Item = {
    name: string
    icon?: string
    price: string
  }

  const { checkout } = useFlowStore();

  const isDefined = <T>(value: T | undefined): value is T => {
    return value !== undefined;
  };

  const items = computed(() => {
    return [
      checkout.meta.receipt.reservation_costs,
    ].filter(isDefined)
  });
</script>
