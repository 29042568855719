<template>
  <Teleport to="body">
    <Transition name="modal">
      <div v-if="isOpened" class="fixed inset-0 z-50 bg-qwr-haze-800/70 px-4 py-20 lg:px-10 grid overflow-auto" @click="closeModal">
        <div class="relative max-w-2xl w-full flex-none m-auto">
          <div class="modal-window bg-white shadow-xl rounded-md relative overflow-clip" @click.stop>
            <header v-if="slots.header">
              <slot name="header" :modal="slotContext" />
            </header>

            <main v-if="slots.default" class="p-4 lg:p-8">
              <slot :modal="slotContext" />
            </main>

            <footer v-if="slots.footer" class="mx-4 lg:mx-8 py-4 border-t border-qwr-haze-100">
              <slot name="footer" :modal="slotContext" />
            </footer>
          </div>

          <button type="button" @click="closeModal" class="absolute -top-10 -right-2 w-10 h-10 flex justify-center items-center">
            <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
            </svg>
          </button>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script lang="ts" setup>
  import { ref } from 'vue';

  const slots = defineSlots();

  const emit = defineEmits([
    'close',
    'open',
  ]);

  const isOpened = ref(false);

  const openModal = () => {
    isOpened.value = true;
    document.body.classList.add('overflow-clip');
    emit('open');
  };

  const closeModal = () => {
    emit('close');
    document.body.classList.remove('overflow-clip');
    isOpened.value = false;
  };

  const slotContext = {
    openModal,
    closeModal,
    isOpened,
  };

  defineExpose({
    openModal,
    closeModal,
  });
</script>
