<template>
  <component :is="logo" />
</template>

<script setup lang="ts">
  import { computed } from 'vue';
  import config from '@/config';
  import LogoBadnieuwescans from '@/theme/assets/thermen-badnieuweschans.svg?component';
  import LogoBerendonck from '@/theme/assets/thermen-berendonck.svg?component';
  import LogoBussloo from '@/theme/assets/thermen-bussloo.svg?component';
  import LogoMaarssen from '@/theme/assets/thermen-maarssen.svg?component';
  import LogoMaastricht from '@/theme/assets/thermen-maastricht.svg?component';
  import LogoSoesterberg from '@/theme/assets/thermen-soesterberg.svg?component';

  const logo = computed(() => {
    switch (config.theme) {
      case 'badnieuweschans': return LogoBadnieuwescans;
      case 'berendonck': return LogoBerendonck;
      case 'bussloo': return LogoBussloo;
      case 'maarssen': return LogoMaarssen;
      case 'maastricht': return LogoMaastricht;
      case 'soesterberg': return LogoSoesterberg;
      default: return LogoBussloo;
    }
  });
</script>
